<template>
    <h1>
        <span v-html="$t('radban.korzysci.title')"></span>
    </h1>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/config", "~@/assets/scss/media-queries",
    "~@/assets/scss/default-styles";

h1 {
    color: $primary-blue;
    letter-spacing: -2.48px;
    line-height: 73px;
    text-align: center;
    margin: 0 auto 80px auto;
}
@include respond-below(lg) {
    h1 {
        width: 85vw;
        .upper-index-h1 {
            font-size: $fontSize24;
        }
    }
}
@include respond-below(sm) {
    h1 {
        line-height: 34.64px;
        width: auto;
        margin: 55px auto 63px auto;
        letter-spacing: -1.12px;
        text-align: center;
        .upper-index-h1 {
            font-size: $fontSize15;
        }
    }
}
</style>
