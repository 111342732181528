<template>
    <section class="team-home">
        <h1 class="anim"><div class="div-anim" data-delay="0.2s">{{$t('team.banner')}}</div></h1>
    </section>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/config", "~@/assets/scss/media-queries", "~@/assets/scss/default-styles";

.team-home {
    background-image: url("~@/assets/images/team.jpg");
    background-size: cover;
    background-position: center;
    height: 100vh;
    @include flex-center-column;
    position: relative;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.05);
        pointer-events: none;
    }
    h1 {
        text-align: center;
        color: rgb(243, 241, 241);
        z-index: 2;
    }
    @include respond-below(lg) {
        height: 55vw;
        h1 {
            font-size: $fontSize48;
            letter-spacing: 0;
            line-height: normal;
            width: 100%;
        }
    }
    @include respond-below(sm) {
        h1 {
            font-size: $fontSize30;
            letter-spacing: 0;
            line-height: 32px;
        }
    }
}
</style>
