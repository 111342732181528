<template>
    <section class="korban-description">
        <div class="korban-description-container">
            <h1>eCRF</h1>
            <h2>
                <span v-html="$t('ecrf.gallery.content')"></span>
            </h2>
        </div>
        <div class="korban-description-picture"></div>
    </section>
</template>

<style lang="scss" scoped>
.korban-description-picture {
    background-image: url("~@/assets/images/scr/projekt-ecrf.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 28px;
}
.korban-description-container {
    justify-content: center;
}
</style>