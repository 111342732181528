<template>
	<section>
		<div class="container">
			<div class="container-picture"></div>
			<div class="container-content">
				<!-- <p class="container-content-paragraph-small paragraph-fill">Zespół ekspertów</p> -->
				<div>
					<h1>Michał Kasiewicz</h1>
					<p class="container-content-paragraph-small"><span v-html="$t('team.grid.wspolzalozyciel')"></span></p>
					<p class="container-content-paragraph-big">
						<span v-html="$t('MK.banner.content')"></span>
					</p>
				</div>
			</div>
		</div>
	</section>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/config", "~@/assets/scss/media-queries", "~@/assets/scss/default-styles";

section {
	padding-top: 0;
	padding-bottom: 110px;
	.container {
		display: grid;
		grid-template-columns: 50vw 50vw;
		grid-template-rows: 90vh;
		&-content {
			padding: 42px 42px 95px 42px;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			background: $light-blue;
			&-paragraph-big {
				width: 60%;
			}
			&-paragraph-small {
				margin-bottom: 42px;
			}
			.paragraph-fill {
				height: 50%;
			}
			h1 {
				font-size: 56px;
				letter-spacing: -2.24px;
				line-height: 50.3px;
			}
		}
		&-picture {
			background-image: url("~@/assets/images/michal-kasiewicz2.jpg");
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			-webkit-transform: scaleX(-1);
			transform: scaleX(-1);
		}
	}
	@include respond-below(xl) {
		.container {
			grid-template-rows: 70vh;
			&-content {
				padding-bottom: 50px;
				&-paragraph-big {
					width: 100%;
				}
			}
			&-picture {
				width: 100%;
				height: 100%;
				background-size: cover;
				background-position: 30% 0;
			}
		}
	}
	@include respond-below(lg) {
		padding-bottom: 55px;
		.container {
			grid-template-columns: 100vw;
			grid-template-rows: 100vw auto;
			&-content {
				padding: 60px 30px 60px 30px;
				margin: 0;
				justify-content: start;
				h1 {
					letter-spacing: -1.2px;
					margin: 0;
					padding: 0;
					margin-top: 100px;
					font-size: $fontSize48;
				}
				&-paragraph-small {
					font-size: $fontSize14;
					letter-spacing: -0.56px;
					line-height: 18px;
					margin: 0;
					padding: 0;
				}
				&-paragraph-big {
					display: block;
					font-size: $fontSize16;
					line-height: 19.2px;
					letter-spacing: 0;
					margin-top: 42px;
				}
				.paragraph-fill {
					height: auto;
				}
				h1,
				p {
					width: 85vw;
					margin-left: auto;
					margin-right: auto;
				}
			}
		}
	}
	@include respond-below(sm) {
		.container {
			&-content {
				justify-content: space-between;
				padding: 30px 30px 60px 30px;
				h1 {
					margin-top: 0;
					line-height: 35.3px;
					font-size: $fontSize30;
				}
			}
		}
	}
}
</style>
