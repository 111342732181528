<template>
	<section class="products-banair" id="AirBan">
		<div class="products-banair-title">
			<h1>
				{{ $t("Produkty.AirBanGal.title") }} <b>AirBan™</b><br />
				{{ $t("Produkty.AirBanGal.title2") }}
			</h1>
			<img src="~@/assets/images/b_a-circleicon.svg" alt="BanAir" />
		</div>
		<div class="products-banair-content">
			<h2>
				{{ $t("Produkty.AirBanGal.contentUp") }}
			</h2>
			<h2><b>AirBan™</b> {{ $t("Produkty.AirBanGal.contentDown") }}</h2>
		</div>
	</section>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/config", "~@/assets/scss/media-queries", "~@/assets/scss/default-styles";
.products-banair {
	display: grid;
	grid-template-columns: 400px 800px;
	grid-template-rows: 400px;
	justify-content: center;
	padding: 0 0 110px 0;

	&-title {
		background-color: $primary-blue;
		padding: 40px;
		position: relative;
		img {
			width: 110px;
			height: 55px;
			position: absolute;
			bottom: 40px;
		}
	}
	&-content {
		background-color: $dark-blue;
		padding: 45px;
		h2 {
			line-height: 27.6px;
			margin-bottom: 24px;
			width: 640px;
		}
		sup {
			font-size: $fontSize12;
		}
	}
	@include respond-below(xl) {
		grid-template-columns: 30vw 60vw;
		&-title {
			padding: 30px;
			h1 {
				font-size: $fontSize48;
			}
		}
		&-content {
			padding: 30px;
			h2 {
				font-size: $fontSize20;
				width: auto;
				br {
					display: none;
				}
			}
		}
	}
	@include respond-below(lg) {
		grid-template-columns: 85vw;
		grid-template-rows: auto 60vw;
		&-title {
			padding: 28px;
			h1 {
				margin-bottom: 80px;
			}
			img {
				bottom: 28px;
			}
		}
		&-content {
			padding: 28px;
			h2 {
				font-size: $fontSize18;
				line-height: normal;
			}
		}
	}
	@include respond-below(sm) {
		padding-bottom: 50px;
		grid-template-columns: minmax($mobile-grid-width-min, $mobile-grid-width-max);
		grid-template-rows: repeat(2, minmax($mobile-grid-width-min, $mobile-grid-width-max));
		&-title {
			padding: 30px;
			img {
				width: 80px;
				height: 40px;
				bottom: 32px;
			}
			.upper-index-h1 {
				font-size: $fontSize24;
			}
		}
		&-content {
			display: block;
			padding: 28px;
			h2 {
				width: 95%;
				line-height: 18px;
				letter-spacing: 0;
				font-size: $fontSize15;
			}
			sup {
				font-size: $fontSize8;
			}
		}
	}
}
</style>
