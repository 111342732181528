<template>
	<section class="products-overview">
		<div class="products-overview-grid">
			<div class="products-overview-grid-content">
				<h3><span v-html="$t('Produkty.korzysci.1.title')"></span></h3>
				<p>
					{{ $t("korzysc") }}<br /><br />
					<span v-html="$t('Produkty.korzysci.1.content')"></span>
				</p>
				<img src="~@/assets/images/icons/burger.svg" alt="BanAir" />
			</div>
			<div class="products-overview-grid-content">
				<h3><span v-html="$t('Produkty.korzysci.2.title')"></span></h3>
				<p>
					{{ $t("korzysc") }}<br /><br />
					<span v-html="$t('Produkty.korzysci.2.content')"></span>
				</p>
				<img src="~@/assets/images/icons/gear.svg" alt="BanAir" />
			</div>
			<div class="products-overview-grid-content">
				<h3><span v-html="$t('Produkty.korzysci.3.title')"></span></h3>
				<p>
					{{ $t("korzysc") }}<br /><br />
					<span v-html="$t('Produkty.korzysci.3.content')"></span>
				</p>
				<img src="~@/assets/images/icons/arrow.svg" alt="BanAir" />
			</div>
			<div class="products-overview-grid-content">
				<h3><span v-html="$t('Produkty.korzysci.4.title')"></span></h3>
				<p>
					{{ $t("korzysc") }}<br /><br />
					<span v-html="$t('Produkty.korzysci.4.content')"></span>
				</p>
				<img src="~@/assets/images/icons/arrow.svg" alt="BanAir" />
			</div>
			<div class="products-overview-grid-content">
				<!-- <h3>Pulpit roboczy dla użytkowników</h3>
                <p>
                    Korzyść:<br /><br />
                    Optymalizacja kosztów i jednocześnie ich minimalizacja
                    związanych z wprowadzaniem danych do systemu
                </p>
                <img src="~@/assets/images/products-burger.svg" alt="BanAir" /> -->
			</div>
			<div class="products-overview-grid-content">
				<h3><span v-html="$t('Produkty.korzysci.5.title')"></span></h3>
				<p>
					{{ $t("korzysc") }}<br /><br />
					<span v-html="$t('Produkty.korzysci.5.content')"></span>
				</p>
				<img src="~@/assets/images/icons/chart.svg" alt="BanAir" />
			</div>
			<div class="products-overview-grid-content">
				<h3><span v-html="$t('Produkty.korzysci.6.title')"></span></h3>
				<p>
					{{ $t("korzysc") }}<br /><br />
					<span v-html="$t('Produkty.korzysci.6.content')"></span>
				</p>
				<img src="~@/assets/images/icons/chartonhand.svg" alt="BanAir" />
			</div>
			<div class="products-overview-grid-content">
				<h3><span v-html="$t('Produkty.korzysci.7.title')"></span></h3>
				<p>
					{{ $t("korzysc") }}<br /><br />
					<span v-html="$t('Produkty.korzysci.7.content')"></span>
				</p>
				<img src="~@/assets/images/icons/bulb.svg" alt="BanAir" />
			</div>
			<div class="products-overview-grid-content">
				<h3><span v-html="$t('Produkty.korzysci.8.title')"></span></h3>
				<p>
					{{ $t("korzysc") }}<br /><br />
					<span v-html="$t('Produkty.korzysci.8.content')"></span>
				</p>
				<img src="~@/assets/images/icons/chartonhand.svg" alt="BanAir" />
			</div>
		</div>
	</section>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/media-queries";

.products-overview {
	@include respond-above(xl) {
		padding-bottom: 80px;
	}
}
</style>
