<template>
    <h1>
        <span v-html="$t('optimalization.zobacz')"></span>
    </h1>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/config", "~@/assets/scss/media-queries",
    "~@/assets/scss/default-styles";

h1 {
    color: $primary-blue;
    text-align: center;
    margin: 100px auto 80px auto;
    @include respond-below(xl) {
        width: 85vw;
    }
    @include respond-below(lg) {
        margin: 0 auto 55px auto;
    }
    @include respond-below(sm) {
        margin: 0 auto 25px auto;
    }
}
</style>
