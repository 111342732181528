<template>
	<section class="korban-description">
		<div class="korban-description-container">
			<h1>RadBan<sup class="upper-index-h1">TM</sup></h1>
			<h2>
				<span v-html="$t('radban.gallery.content')"></span>
			</h2>
		</div>
		<div class="korban-description-picture"></div>
	</section>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/media-queries";

.korban-description-picture {
	background-image: url("~@/assets/images/scr/radban.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}
.korban-description-container {
	justify-content: center;
}
</style>
