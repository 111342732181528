<template>
	<section class="selected-person-experience">
		<div>
			<h1><span v-html="$t('MK.description.exp')"></span></h1>
		</div>
		<div>
			<h2>
				<span v-html="$t('MK.description.details')"></span>
			</h2>
		</div>
	</section>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/config", "~@/assets/scss/media-queries", "~@/assets/scss/default-styles";

.selected-person-experience {
	background-color: $background-whiteish;
	display: grid;
	grid-template-columns: 650px 550px;
	justify-content: center;
	margin-bottom: 80px;
	div {
		h1 {
			color: $primary-blue;
			font-size: 56px;
		}
		h2 {
			line-height: 27.6px;
			color: $primary-blue;
		}
	}
	@include respond-below(xl) {
		grid-template-columns: 85vw;
		h1 {
			margin-bottom: 30px;
			font-size: $fontSize48;
			line-height: normal;
		}
	}
	@include respond-below(sm) {
		margin-bottom: 60px;
		div {
			h1 {
				letter-spacing: -1.2px;
				line-height: 63.63px;
				font-size: $fontSize30;
				margin: 0;
			}
			h2 {
				letter-spacing: 0px;
				line-height: 18px;
			}
		}
	}
}
</style>
