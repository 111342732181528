<template>
	<section class="team-groups">
		<h1>
			<span v-html="$t('team.grid.h1')"></span>
		</h1>
		<h2>
			<span v-html="$t('team.grid.h2')"></span>
		</h2>
		<div class="team-groups-container">
			<div class="team-groups-container-picture">
				<img src="~@/assets/images/michal-kasiewicz.jpg" alt="" class="mk-picture" />
			</div>
			<div class="team-groups-container-content">
				<!-- <p class="team-groups-container-content-paragraph-small">Zespół doradców</p> -->
				<h1>Michał Kasiewicz</h1>
				<p class="team-groups-container-content-paragraph-small"><span v-html="$t('team.grid.wspolzalozyciel')"></span></p>
				<p class="team-groups-container-content-paragraph-big">
					<span v-html="$t('team.grid.mk.content')"></span>
				</p>
				<button class="btn button-white" @click="$router.push('/team/michal-kasiewicz')">{{ $t("team.grid.mk.poznaj") }}</button>
			</div>
			<div class="team-groups-container-content">
				<!-- <p class="team-groups-container-content-paragraph-small">Zespół doradców</p> -->
				<h1>Mariusz Budzisz</h1>
				<p class="team-groups-container-content-paragraph-small"><span v-html="$t('team.grid.wspolzalozyciel')"></span></p>
				<p class="team-groups-container-content-paragraph-big">
					<span v-html="$t('team.grid.mb.content')"></span>
				</p>
				<button class="btn button-white" @click="$router.push('/team/mariusz-budzisz')">
					<span v-html="$t('team.grid.mb.poznaj')"></span>
				</button>
			</div>
			<div class="team-groups-container-picture">
				<img src="~@/assets/images/mariusz-budzisz.jpg" alt="" class="mb-picture" />
			</div>
			<div class="team-groups-container-picture">
				<img src="~@/assets/images/pawel-gawronski.jpg" alt="" class="pg-picture" />
			</div>
			<div class="team-groups-container-content">
				<!-- <p class="team-groups-container-content-paragraph-small">Zespół doradców</p> -->
				<h1>Paweł Gawroński</h1>
				<p class="team-groups-container-content-paragraph-small"><span v-html="$t('team.grid.wspolzalozyciel')"></span></p>
				<p class="team-groups-container-content-paragraph-big">
					<span v-html="$t('team.grid.pg.content')"></span>
				</p>
				<button class="btn button-white" @click="$router.push('/team/pawel-gawronski')">
					<span v-html="$t('team.grid.pg.poznaj')"></span>
				</button>
			</div>
		</div>
	</section>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/config", "~@/assets/scss/media-queries", "~@/assets/scss/default-styles";

.team-groups {
	padding: 48px 0 220px 0;
	h1,
	h2 {
		color: $primary-blue;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
	}
	h2 {
		width: 850px;
		margin: 36px auto 113px auto;
	}
	&-container {
		display: grid;
		grid-template-columns: repeat(2, 600px);
		grid-template-rows: repeat(3, 600px);
		justify-content: center;
		&-picture {
			overflow: hidden;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		&-content {
			padding: 42px;
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			&:nth-child(2) {
				background-color: $light-bluev2;
			}
			&:nth-child(3) {
				background-color: $primary-blue;
			}
			&:nth-child(6) {
				background-color: $dark-blue;
			}
			h1 {
				margin: 10px 0 3px 0;
				color: #ebebed;
				font-size: 56px;
				letter-spacing: -2.24px;
				line-height: 50.3px;
				text-align: left;
			}
			&-paragraph-small {
				font-size: $fontSize14;
				line-height: 16.8px;
			}
			&-paragraph-big {
				font-size: $fontSize16;
				line-height: 19.2px;
				margin: 42px 0 2rem 0;
				width: 370px;
			}
			.btn,
			.button-white {
				height: 55px;
			}
		}
	}
	@include respond-below(xl) {
		&-container {
			grid-template-columns: repeat(2, 45vw);
			grid-template-rows: repeat(3, 45vw);
			&-content {
				h1 {
					font-size: $fontSize48;
				}
			}
		}
	}
	@include respond-below(lg) {
		h1,
		h2 {
			width: 85vw;
		}
		&-container {
			grid-template-columns: repeat(2, 42.5vw);
			grid-template-rows: repeat(3, 42.5vw);

			&-content {
				padding: 28px;
				h1 {
					font-size: $fontSize32;
					line-height: normal;
					width: 100%;
				}
				&-paragraph-big {
					display: none;
				}
				&-paragraph-small {
					margin-bottom: 1.5rem;
				}
			}
		}
	}
	@include respond-below(md) {
		&-container {
			&-content {
				padding: 24px;
				h1 {
					margin-top: 20px;
				}
			}
		}
	}
	@include respond-below(sm) {
		padding: 66px 0 75px 0;
		h2 {
			max-width: 100vw;
			margin: 28px auto 65px auto;
			font-size: $fontSize15;
		}
		&-container {
			grid-template-columns: minmax($mobile-grid-width-min, $mobile-grid-width-max);
			grid-template-rows: repeat(6, minmax($mobile-grid-width-min, $mobile-grid-width-max));
			&-picture {
				&:nth-child(4) {
					grid-row: 3;
				}
			}
			&-content {
				padding: 30px;
				margin-bottom: 25px;
				justify-content: center;
				h1 {
					font-size: $fontSize30;
				}

				p {
					font-size: $fontSize14;
				}
				&-paragraph-big {
					display: none;
				}

				.btn {
					text-align: center;
					width: 197px;
					padding: 19px 16px;
					position: absolute;
					bottom: 32px;
				}
			}
		}
	}
}
</style>
