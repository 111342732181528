<template>
    <section class="korban-description">
        <div class="korban-description-container">
            <h1>SorBan<sup class="upper-index-h1">TM</sup></h1>
            <h2>
                <span v-html="$t('sorban.gallery.content')"></span>
            </h2>
        </div>
        <div class="korban-description-picture"></div>
    </section>
</template>

<style lang="scss" scoped>
.korban-description-picture {
    background-image: url("~@/assets/images/scr/projekt-sorban.png");
    background-position: 0 500%;
    background-size: cover;
}
</style>
