<template>
    <section class="korban-description">
        <div class="korban-description-container">
            <h1>
                <span v-html="$t('dedykowane.gallery.title')"></span>
            </h1>
            <h2>
                <span v-html="$t('dedykowane.gallery.content')"></span>
            </h2>
        </div>
        <div class="korban-description-picture">
            <ProductsGallery />
        </div>
    </section>
</template>

<script>
import ProductsGallery from "@/components/default/ProductsGallery.vue";

export default {
    components: {
        ProductsGallery,
    },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/config", "~@/assets/scss/media-queries";
.korban-description {
    margin-bottom: 40px;
}
.korban-description-container {
    justify-content: center;
}
</style>
