<template>
    <section class="korban">
        <video
            autoplay
            muted
            loop
            playsinline
            class="banner-video banerVideo-desktop"
        >
            <source
                :src="require(`@/assets/videos/${videoSource}`)"
                type="video/mp4"
            />
        </video>
        <section class="korban-content">
            <div class="korban-content-top">
                <h6><span v-html="$t('optimalization.header.title')"></span></h6>
            </div>
            <div class="korban-content-bottom">
                <h1>
                    <span v-html="$t('optimalization.header.content')"></span>
                </h1>
            </div>
        </section>
    </section>
</template>

<script>
export default {
    data() {
        return {
            videoSource: "400x200/optimalization.mp4",
        };
    },
    computed: {
        screenWidth() {
            return this.$store.state.screenWidth;
        },
    },
    created() {
        if (this.screenWidth < 992) {
            this.videoSource = "400x200/optimalization.mp4";
        } else {
            this.videoSource = "1366x654/optimalization_medium.mp4";
        }
    },
};
</script>
