<template>
	<h1>
		<span v-if="this.$store.state.screenWidth >= 992"  v-html="$t('Produkty.korzysci.title')"></span>
		<span v-if="this.$store.state.screenWidth < 992"  v-html="$t('Produkty.korzysci.titleMobile')"></span>
	</h1>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/config", "~@/assets/scss/media-queries", "~@/assets/scss/default-styles";
h1 {
	color: $primary-blue;
	text-align: center;
	width: 100%;
	margin: 0 auto 97px auto;
}
@include respond-above(lg) {
    .new-br {
        display: block;
    }
}
@include respond-below(lg) {
    .new-br {
        display: none;
    }
}
@include respond-below(xl) {
	h1 {
		width: 85vw;
	}
	
}
@include respond-below(sm) {
	h1 {
		margin-bottom: 30px;
	}
}
</style>
