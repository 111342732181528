<template>
	<section class="korban-description">
		<div class="korban-description-container">
			<h1>HosPanel<sup class="upper-index-h1">TM</sup></h1>
			<h2>
				<span v-html="$t('hospanel.gallery.content')"></span>
			</h2>
		</div>
		<div class="korban-description-picture"></div>
	</section>
</template>

<style lang="scss" scoped>
.korban-description-picture {
	background-image: url("~@/assets/images/scr/hospanel.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	margin: 28px 0;
}
.korban-description-container {
	justify-content: center;
}
</style>
