<template>
	<section class="products">
		<h1>
			{{ $t("Produkty.baner.header.poznaj") }} <br class="mobile-br" />
			{{ $t("Produkty.baner.header.ktore") }}<br />
			{{ $t("Produkty.baner.header.specjalnie") }}<br class="mobile-br" />
			{{ $t("Produkty.baner.header.med") }}
		</h1>
		<h2>
			{{ $t("Produkty.baner.description.bardzo") }}<br class="mobile-br" />
			{{ $t("Produkty.baner.description.ktore") }}<br />
            {{ $t("Produkty.baner.description.realnie") }}<br class="mobile-br" />
			{{ $t('Produkty.baner.description.zasoby')}}<br />
			{{ $t('Produkty.baner.description.stad')}}<br class="mobile-br" />
			{{ $t('Produkty.baner.description.sie')}}<br class="desktop-br" />
			{{ $t('Produkty.baner.description.jednoczesnie')}}<br class="mobile-br" />
			{{ $t('Produkty.baner.description.stanowiac')}}<br class="mobile-br" />
			{{ $t('Produkty.baner.description.dobierac')}}<br class="desktop-br" />
			{{ $t('Produkty.baner.description.do')}}
		</h2>
	</section>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/config", "~@/assets/scss/media-queries", "~@/assets/scss/default-styles";

.products {
	margin: 155px auto 80px auto;
	h1,
	h2 {
		color: $primary-blue;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
	}
	h2 {
		margin-top: 28px;
		width: auto;
	}
	@include respond-below(lg) {
		margin: 53px auto 62px auto;
		h1,
		h2 {
			width: 85vw;
			br {
				display: none;
			}
		}
		h2 {
			font-size: $fontSize18;
		}
	}
	@include respond-below(sm) {
		h1,
		h2 {
			width: 100%;
			br {
				display: block;
			}
			.desktop-br {
				display: none;
			}
		}
		h2 {
			font-size: $fontSize15;
		}
	}
}
</style>
