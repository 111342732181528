<template>
    <section class="products-overview">
        <div class="products-overview-grid">
            <div class="products-overview-grid-content">
                <h3><span v-html="$t('dedykowane.korzysci.1.title')"></span></h3>
				<p>
					{{ $t("korzysc") }}<br /><br />
					<span v-html="$t('dedykowane.korzysci.1.content')"></span>
				</p>
                <img src="~@/assets/images/icons/chart.svg" alt="BanAir" />
            </div>
            <div class="products-overview-grid-content">
                <h3><span v-html="$t('dedykowane.korzysci.2.title')"></span></h3>
				<p>
					{{ $t("korzysc") }}<br /><br />
					<span v-html="$t('dedykowane.korzysci.2.content')"></span>
				</p>
                <img src="~@/assets/images/icons/arrow.svg" alt="BanAir" />
            </div>
            <div class="products-overview-grid-content">
                <h3><span v-html="$t('dedykowane.korzysci.3.title')"></span></h3>
				<p>
					{{ $t("korzysc") }}<br /><br />
					<span v-html="$t('dedykowane.korzysci.3.content')"></span>
				</p>
                <img src="~@/assets/images/icons/chartonhand.svg" alt="BanAir" />
            </div>
            <div class="products-overview-grid-content">
                <h3><span v-html="$t('dedykowane.korzysci.4.title')"></span></h3>
				<p>
					{{ $t("korzysc") }}<br /><br />
					<span v-html="$t('dedykowane.korzysci.4.content')"></span>
				</p>
                <img src="~@/assets/images/icons/burger.svg" alt="BanAir" />
            </div>
            <div class="products-overview-grid-content">
                <video muted loop autoplay playsinline>
                    <source src="~@/assets/videos/400x400/specific-square.mp4" type="video/mp4" />
                </video>
            </div>
            <div class="products-overview-grid-content">
                <h3><span v-html="$t('dedykowane.korzysci.5.title')"></span></h3>
				<p>
					{{ $t("korzysc") }}<br /><br />
					<span v-html="$t('dedykowane.korzysci.5.content')"></span>
				</p>
                <img src="~@/assets/images/icons/bulb.svg" alt="BanAir" />
            </div>
            <div class="products-overview-grid-content">
                <h3><span v-html="$t('dedykowane.korzysci.6.title')"></span></h3>
				<p>
					{{ $t("korzysc") }}<br /><br />
					<span v-html="$t('dedykowane.korzysci.6.content')"></span>
				</p>
                <img src="~@/assets/images/icons/gear.svg" alt="BanAir" />
            </div>
            <div class="products-overview-grid-content">
                <h3><span v-html="$t('dedykowane.korzysci.7.title')"></span></h3>
				<p>
					{{ $t("korzysc") }}<br /><br />
					<span v-html="$t('dedykowane.korzysci.7.content')"></span>
				</p>
                <img src="~@/assets/images/icons/burger.svg" alt="BanAir" />
            </div>
            <div class="products-overview-grid-content">
                <h3><span v-html="$t('dedykowane.korzysci.8.title')"></span></h3>
				<p>
					{{ $t("korzysc") }}<br /><br />
					<span v-html="$t('dedykowane.korzysci.8.content')"></span>
				</p>
                <img src="~@/assets/images/icons/arrow.svg" alt="BanAir" />
            </div>
        </div>
    </section>
</template>
